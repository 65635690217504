<template>
  <div class="login">
    <div class="wrapper-login">
      <div class="wrapper-data-login">
        <div class="login-header">เข้าสู่ระบบแอดมิน</div>
        <v-divider class="mt-2 mb-4"></v-divider>
        <v-form
          v-on:submit.prevent="submitLogin()"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div>
            <label class="black--text" for="">ชื่อผู้ใช้</label>
            <v-text-field
              class="mt-1"
              prepend-inner-icon="mdi-account"
              outlined
              dense
              :rules="usernameRule"
              v-model.trim="dataLogin.username"
              required
            ></v-text-field>
          </div>
          <div>
            <label class="black--text" for="">รหัสผ่าน</label>
            <v-text-field
              prepend-inner-icon="mdi-lock"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              outlined
              dense
              class="mt-1"
              :rules="passwordRule"
              v-model.trim="dataLogin.password"
              require
            ></v-text-field>
          </div>

          <div class="text-center mt-4 mb-4">
            <v-btn
              color="black"
              class="white--text"
              block
              type="submit"
              :loading="loadLogin"
              @click.prevent="submitLogin()"
              >เข้าสู่ระบบ</v-btn
            >
          </div>
        </v-form>
        <div class="text-center">
          <v-btn
            @click.prevent="$router.push('/management/register')"
            color="black"
            text
            >ลงทะเบียน</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/services/Auth.service";
import Swal from "sweetalert2";
export default {
  name: "login",
  data() {
    return {
      show1: false,
      loadLogin: false,
      valid: true,
      usernameRule: [
        (v) => !!v || "กรุณากรอกชื่อผู้ใช้ของคุณเพื่อเข้าสู่ระบบ",
        (v) => /^[a-zA-Z0-9\s]+$/.test(v) || "รูปแบบชื่อผู้ใช้ไม่ถูกต้อง",
      ],
      passwordRule: [
        (v) => !!v || "กรุณากรอกรหัสผ่านของคุณเพื่อเข้าสู่ระบบ",
        (v) =>
          /^[a-zA-Z0-9@$!%*?&%#._-]+$/.test(v) || "รูปแบบรหัสผ่านไม่ถูกต้อง",
        (v) => v.length >= 6 || "รหัสผ่านต้องมี 6 ตัวขึ้นไป",
      ],
      dataLogin: {
        username: "",
        password: "",
      },
    };
  },
  created() {
    if (localStorage.getItem(process.env.VUE_APP_AUTH)) {
      this.$router.push("/management/add-tracking");
    }
  },
  methods: {
    setAdminLocalStorage(response) {
      return new Promise((resolve) => {
        localStorage.setItem(
          process.env.VUE_APP_AUTH,
          JSON.stringify(response)
        );
        return resolve();
      });
    },
    async submitLogin() {
      if (this.$refs.form.validate()) {
        this.loadLogin = true;
        try {
          const response = await Auth.login(this.dataLogin);
          await this.setAdminLocalStorage(response);
          const checkToken = await Auth.checkAuthenticateToken();
          if (checkToken.msg === true) {
            Swal.fire({
              position: "bottom-end",
              toast: true,
              icon: "success",
              title: "เข้าสู่ระบบสำเร็จ",
              showConfirmButton: false,
              timer: 3000,
            });
            this.$router.push("/management/add-tracking");
          }
          this.loadLogin = false;
        } catch (error) {
          this.loadLogin = false;
          Swal.fire({
            icon: "error",
            title: error.msg,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.login-header {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 2px;
  color: rgba(34, 34, 34, 0.9);
}

.wrapper-login {
  background: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 600px;
  border-radius: 4px;
  overflow: hidden;
  padding: 2rem 0;
}
.wrapper-image-login {
  background: #2ab473;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.wrapper-data-login {
  padding: 0 2rem;
  margin: auto 0;
}
.button_box {
  text-align: center;
}
.button_box .button {
  margin: 30px 20px;
}
@media only screen and (max-width: 700px) {
  .wrapper-login {
    width: 95%;
  }
}
@media only screen and (max-width: 500px) {
  .wrapper-login {
    width: 100%;
    border-radius: 0;
    top: 50%;
    height: 100vh;
  }
}
</style>